import React from 'react'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'

const ImpactStoriesSlide = ({ story }) => {
    return (
        <div className="impact-story">
            <div className="image">
                <GatsbyImage image={story?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={story?.image?.altText} />
            </div>
            <div className="content">
                <h3>
                    {story.title}
                </h3>
                <span className="date">
                    {story.date}
                </span>
                {story.story ? parse(story.story) : ''}
            </div>
        </div>
    )
}

const ImpactStories = ({ data }) => {
    return (
        <section className="impact">
            <div className="container">
                <div className="heading">
                    <h2>
                        {data.title}
                    </h2>
                    {data.content ? parse(data.content) : ''}
                </div>
                <div className="impact-wrapper">
                    <Swiper modules={[Navigation]} spaceBetween={50} slidesPerView={1} autoHeight={true} navigation>
                        {data.stories && data.stories.length && data.stories.map((story, index) => (
                            <SwiperSlide key={`story-${index}`}>
                                <ImpactStoriesSlide story={story} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default ImpactStories
