import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'

const BlackBox = ({ data }) => {
    return (
        <section className="black-box">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {parse(data.content)}
                    </div>
                    <div className="col">
                        {data.button && data.button.title && (
                            <Link className="button white" to={data.button.url} target={data.button.target}>
                                {data.button.title}
                            </Link>
                        )}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default BlackBox