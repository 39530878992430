import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'

import '../../scss/network.scss'

const Network = ({ data : { page, collections }, pageContext }) => {

    const { featuredItem, items } = page.pageContent.network

    return (
        <Fragment>
             <section className="button-group">
                <div className="container">
                    <div className="row">
                        {/*<div className="col">
                            <button className="button white">
                                Documentaries
                            </button>
                        </div>
                        <div className="col">
                            <button className="button white">
                                Shorts
                            </button>
                        </div>*/}
                        <div className="col">
                            <a href="https://www.amazon.com/United-We-Heal-Ben-Lunak/dp/B09T6QLPLD/ref=sr_1_1?crid=NXXZAUVAJ3IH&keywords=united+we+heal&qid=1650921599&s=instant-video&sprefix=united+we+heal%2Cinstant-video%2C117&sr=1-1#customerReviews" target="_blank" className="button blue">
                                Watch at Home
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {featuredItem?.video && (
                <section className="featured">
                    <div className="container">
                        <div className='player-wrapper player-wrapper-main'>
                            <ReactPlayer
                              className='react-player'
                              url={featuredItem.video}
                              width='100%'
                              height='100%'
                              controls
                            />
                        </div>
                    </div>
                </section>
            )}
            <section className="networks">
                <div className="container">
                    <div className="network-grid">
                        {items?.length && items.map((item, index) => {
                            return (
                                <div className="item">
                                    {item?.video && (
                                        <div className='player-wrapper'>
                                            <ReactPlayer
                                              className='react-player'
                                              url={item.video}
                                              width='100%'
                                              height='100%'
                                              controls
                                            />
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Network
