import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

import GradientHeader from './gradient-header'
import ContentBar from './content-bar'
import ReadMore from './read-more'

import '../../scss/team.scss'

const Department = ({ department, index }) => {

    const { allFile } = useStaticQuery(graphql`
        query PlaceholderImageQuery {
            allFile(filter: {name: {eq: "team-placeholder"}}) {
                files: nodes {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            placeholder: BLURRED
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    `)
    const defaultImage = {
        data: allFile?.files[0]?.childImageSharp?.gatsbyImageData,
        alt: 'Mission 22 Default Header'
    }

    const { members } = department
    return (
        <section className="department">
            {department.slug !== 'regional-ambassador-leaders' &&
             department.slug !== 'state-ambassador-leaders' ? (
                 <GradientHeader
                     title={department.name}
                     content={department.description}
                     reverse={index % 2 === 0 ? true : false}
                 />
             ) : (
                <ContentBar backgroundColor={'rgba(0, 0, 0, 0.1)'} centered={true}>
                    <h3 style={{ color: '#003366' }}>
                        {department.name}
                    </h3>
                    <p>
                        {department.description}
                    </p>
                </ContentBar>
             )}
            <div className="container">
                <div className={`member-grid ${department.slug}`}>
                    {members?.length && members.reverse() && members.map(member => (
                        <div className="member" key={member.id}>
                            <div className="image">
                                {member?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData ? (
                                    <GatsbyImage image={member.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={member.title} />
                                ) : (
                                    <GatsbyImage image={defaultImage.data} alt={member.title} />
                                )}
                            </div>
                            <div className="content">
                                <h2>
                                    {member.title}
                                </h2>
                                <div className="description">
                                    <ReadMore>
                                        {member?.content}
                                    </ReadMore>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
        </section>
    )
}

export default Department