import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { Link, useStaticQuery, graphql } from 'gatsby'

import EventCard from './event-card'

import RightArrows from '../../svg/right-arrows.svg'

import '../../scss/events-snapshot.scss'

const EventsSnapshot = ({ data }) => {

    const [currentEvents, setCurrentEvents] = useState([])

    const {allWpEvent : { events }} = useStaticQuery(graphql`
        query EventSnapshotQuery {
            allWpEvent(limit: 6) {
                events: nodes {
                    id
                    title
                    eventData {
                        startDate
                        endDate
                        location {
                            city
                            latitude
                            longitude
                            state
                            postCode
                            streetAddress
                            streetName
                            streetNumber
                            stateShort
                            country
                        }
                    }
                    featuredImage {
                        image: node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: FULL_WIDTH,
                                        aspectRatio: 1,
                                        placeholder: BLURRED,
                                        formats: [AUTO]
                                    )
                                }
                            }
                        }
                    }
                    slug
                }
            }
        }
    `)

    useEffect(() => {
        if (events) {
            const now = new Date();
            const current = events.filter(event => now <= new Date(event.eventData.startDate));
            setCurrentEvents(current)
        }
    }, [events])

    return (
        <section className="events-snapshot">
            <div className="container">
                <div className="heading">
                    <div className="text">
                        <h2>
                            {data.title}
                        </h2>
                        {data.content ? parse(data.content) :  ''}
                    </div>
                    <div className="button">
                        <Link to="/events/">
                            Find an Event
                            <span><RightArrows /></span>
                        </Link>
                    </div>
                </div>
                <div className="events-snapshot-list">
                    {currentEvents?.length ? currentEvents.map(event => (
                        <EventCard key={event.id} event={event} />
                    )) : (
                        <p>There are no upcoming events</p>
                    )}
                </div>
            </div>
        </section>
    )
}

export default EventsSnapshot
