import React, { Fragment, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'


import ContentBar from '../template-parts/content-bar'
import Modal from '../template-parts/modal'
import '../../scss/we-remember.scss'

const WeRemember = ({ data: { page } }) => {

    const { pageHeader, veterans } = page.pageContent.weRemember

    let [isOpen, setIsOpen] = useState(false)
    let [selectedVeteran, setSelectedVeteran] = useState(null)

    const handleToggleDialog = (person) => {
        console.log(person)
        if (!isOpen) {
            setIsOpen(true)
            setSelectedVeteran(person)
        } else {
            setIsOpen(false)
            setSelectedVeteran(null)
        }
    }


    return (
        <Fragment>
            <ContentBar backgroundColor={'#000000'} color={'#ffffff'} centered={true}>
                {pageHeader.content && parse(pageHeader.content)}
            </ContentBar>
            <section className="we-remember-wrapper">
                <div className="container">
                    <div className="we-remember-grid">
                        {veterans.person && veterans.person.length && veterans.person.map((person, index) => (
                            <div className="warrior" key={person.name + index} onClick={() => handleToggleDialog(person)}>
                                <div className="image">
                                    {person?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={person?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={person?.name} />}
                                </div>
                                <div className="name">
                                    {person?.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Modal show={isOpen} toggleModal={handleToggleDialog}>
                <div>
                    <div>
                        {selectedVeteran?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={selectedVeteran?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={selectedVeteran?.name} />}
                    </div>
                    <div>
                        <h3>
                            {selectedVeteran?.name}
                        </h3>
                        <p>
                            {selectedVeteran?.rank}, {selectedVeteran?.branch}
                        </p>
                        <p>
                            <span>
                                {selectedVeteran?.birthDate}
                            </span>
                            {selectedVeteran?.deathDate && (
                                <span>
                                    &nbsp;-&nbsp;
                                </span>
                            )}
                            <span>
                                {selectedVeteran?.deathDate}
                            </span>
                        </p>
                    </div>
                </div>
            </Modal>

        </Fragment>
    )
}

export default WeRemember
