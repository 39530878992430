import React from 'react'
import parse from 'html-react-parser'

import '../../scss/gradient-header.scss'

const GradientHeader = ({ title, content, reverse }) => {

    return (
        <section className={`gradient-header ${reverse ? 'reverse' : ''} `}>
            <div className="gradient">
                <h3>
                    {title}
                </h3>
            </div>
            <div className="content">
                <p>
                    {content && parse(content)}
                </p>
            </div>
        </section>
    )
} 


export default GradientHeader