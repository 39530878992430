import React, { Fragment, useState } from 'react'
import ReactPlayer from 'react-player'
import parse from 'html-react-parser'

import PageHeader from '../template-parts/page-header'
import Modal from '../template-parts/modal'

import '../../scss/memorials.scss'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Memorials = ({ data: { page } }) => {

    const { pageHeader, warAtHome, memorials } = page.pageContent.memorials

    const [formUrl, setFormUrl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleDialog = (url) => {
        if (!isOpen) {
            setIsOpen(true)
            setFormUrl(url)
        } else {
            setIsOpen(false)
            setFormUrl(null)
        }
    }

    return (
        <Fragment>
            <PageHeader>
                <h2>
                    {pageHeader.title}
                </h2>
                {pageHeader.content && parse(pageHeader.content)}

                {pageHeader.button && (
                    <Link to={pageHeader.button.url} className="button black" target={pageHeader.button.target}>
                        {pageHeader.button.title}
                    </Link>
                )}
            </PageHeader>

            <section className="war-at-home">
                <div className="row">
                    <div className="video">
                        <div className="video-wrapper">
                            {warAtHome.video && <ReactPlayer className="video" url={warAtHome.video} light playing controls="false" />}
                            {warAtHome.image && <GatsbyImage image={warAtHome.image.localFile.childImageSharp.gatsbyImageData} alt={warAtHome.image.altText} />}
                        </div>
                    </div>
                    <div className="content">
                        <h3>
                            {warAtHome.title}
                        </h3>
                        {warAtHome.content && parse(warAtHome.content)}
                        {warAtHome.button && (
                            <Link to={warAtHome.button.url} className="button blue" target={warAtHome.button.target}>
                                {warAtHome.button.title}
                            </Link>
                        )}
                        {warAtHome.button2 && (
                            <Link to={warAtHome.button2.url} className="button blue" target={warAtHome.button2.target}>
                                {warAtHome.button2.title}
                            </Link>
                        )}
                    </div>
                </div>
                <div className="row">
                    {warAtHome.gallery && warAtHome.gallery.length && warAtHome.gallery.map((image, index) => (
                        <div className="col" key={`gallery-${index}`}>
                            <GatsbyImage image={image.localFile.childImageSharp.gatsbyImageData} alt={image.altText ? image.altText : 'War at home image ' + index} />
                        </div>
                    ))}
                </div>
            </section>

            <section className="other-memorials">

                {memorials && memorials.length && memorials.map((memorial, index) => (
                    <div className="memorial" key={`memorial-${index}`}>
                        <div className="content">
                            <h3>
                                {memorial.title}
                            </h3>
                            {memorial.content && parse(memorial.content)}
                            {memorial.buttons && memorial.buttons.length && memorial.buttons.map((button, i) => {
                                if (button.button.url.includes('typeform')) {
                                    return (
                                        <button className="button blue" onClick={() => handleToggleDialog(button.button.url)}>
                                            {button.button.title}
                                        </button>
                                    )
                                } else {
                                    return (
                                        <Link to={button.button.url} className="button blue" target={button.button.target} key={`button-${i}`}>
                                            {button.button.title}
                                        </Link>
                                    )
                                }
                            })}
                            
                        </div>
                        <div className="image">
                            {memorial.image && <GatsbyImage image={memorial.image.localFile.childImageSharp.gatsbyImageData} alt={memorial.image.altText ? memorial.image.altText : 'Memorial ' + index} />}
                        </div>
                    </div>
                ))}


            </section>

            <Modal show={isOpen} toggleModal={handleToggleDialog}>
                <iframe src={formUrl} frameBorder="0" width="100%" height="500px"></iframe>
            </Modal>

        </Fragment>
    )
}

export default Memorials
