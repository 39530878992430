import React, { Fragment, useState } from 'react'
import parse from 'html-react-parser'

import '../../scss/read-more.scss'

const ReadMore = ({ children }) => {
    
    const [isReadMore, setIsReadMore] = useState(true)

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === '13') {
            toggleReadMore();
        }
    }

    if (!children) {
        return '';
    }

    return (
        <div className="text">
            {isReadMore ? parse(children.slice(0, 250)) : parse(children)}
            {isReadMore ? "..." : ""}
            <span role="button" tabIndex={0} onClick={toggleReadMore} onKeyDown={handleKeyDown} className="read-or-hide">
                {children.length > 250 && (
                    <Fragment>
                        {isReadMore ? "READ MORE +" : "SHOW LESS -"}
                    </Fragment>
                )}
            </span>
        </div>
    )
}

export default ReadMore