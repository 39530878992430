import React, { Fragment } from 'react'
import parse from 'html-react-parser'

import ContentBar from '../template-parts/content-bar'
import '../../scss/crisis.scss'

const Crisis = ({ data: { page } }) => {
    
    const { content, title, boxes, blueBox } = page.pageContent.crisis
console.log(title)
    return (
        <Fragment>
            <section className="crisis-wrapper">
                {/*<div className="container">
                    <div className="content">
                        {content && parse(content)}
                    </div>
                </div>*/}
                <div className="container">
                    <h2>{title}</h2>
                    {boxes && boxes.map( box => {
                        const phone = (box.phone.charAt(0) === '1') ? box.phone.replace(/\D/g,'').substring(1, 11) : box.phone.replace(/\D/g,'').substring(0, 10)

                        return (
                            <div className="crisis-box">
                                <div className="box-content">
                                    <h3>{box.title}</h3>
                                    <a href={`tel:+1${phone}`} className="phone">{box.phone}</a>
                                </div>
                                <div className="box-buttons">
                                    {box.buttons && box.buttons.map(button => (
                                        <a href={button.link.url} className="button" target={button.link.target}>{button.title}</a>
                                    ))}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <ContentBar backgroundColor={'#003366'} color={'#ffffff'} centered={true}>
                {blueBox && parse(blueBox)}
            </ContentBar>
        </Fragment>
    )
}

export default Crisis
