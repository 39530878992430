import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import '../../scss/partner-item.scss'

const PartnerItem = ({ store }) => {
    return (
        <article className="partner-item">
            <div className="image">
                {store?.logo.localFile.childImageSharp.gatsbyImageData && <GatsbyImage image={store?.logo.localFile.childImageSharp.gatsbyImageData} alt={store?.name} />}
            </div>
            <div className="content">
                <p>
                    <strong>{store?.name}:</strong> {store?.description}
                </p>
                {store?.button && (
                    <Link to={store?.button.url} className="button blue" target={store?.button?.target}>
                        {store?.button.title}
                    </Link>
                )}
            </div>
        </article>
    )
}

export default PartnerItem