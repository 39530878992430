import React, { Fragment, useState } from 'react' 
import parse from 'html-react-parser'
import { Link } from 'gatsby'

import PageHeader from '../template-parts/page-header'
import Modal from '../template-parts/modal'

import Logo from '../../svg/logo-white.svg'

import '../../scss/become-ambassador.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const BecomeAmbassador = ({ data: { page } }) => {

    const {
        pageHeader,
        aboutAmbassadors,
        ambassadorOfTheMonth,
        stateLeaderOfTheMonth,
        signUp,
        buttons
    } = page.pageContent.becomeAnAmbassador


    const [formUrl, setFormUrl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleDialog = (url) => {
        if (!isOpen) {
            setIsOpen(true)
            setFormUrl(url)
        } else {
            setIsOpen(false)
            setFormUrl(null)
        }
    }

    return (
        <Fragment>
            <PageHeader>
                <h2>
                    {pageHeader?.title}
                </h2>
                {pageHeader?.content && parse(pageHeader.content)}

                {pageHeader?.button && (
                    <Link to={pageHeader.button.url} className="button black" target={pageHeader.button.target}>
                        {pageHeader.button.title}
                    </Link>
                )}
            </PageHeader>

            <section className="about-ambassadors">
                <div className="container">
                    <div className="image-wrapper">
                        {aboutAmbassadors?.imageTop?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={aboutAmbassadors.imageTop.localFile.childImageSharp.gatsbyImageData} alt={aboutAmbassadors?.imageTop?.altText} />}
                    </div>
                    <div className="about-row">
                        <div className="content">
                            {aboutAmbassadors?.content && parse(aboutAmbassadors.content)}
                        </div>
                        <div className="image">
                            {aboutAmbassadors?.imageRight?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={aboutAmbassadors.imageRight.localFile.childImageSharp.gatsbyImageData} alt={aboutAmbassadors?.imageRight?.altText} />}
                        </div>
                    </div>
                </div>
            </section>

            <section className="ambassador-of-the-month">
                <div className="feature-row">
                    <div className="col">
                        <Logo />
                        <h3>
                            Ambassador of the month
                        </h3>
                    </div>
                    <div className="col">
                        {ambassadorOfTheMonth?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={ambassadorOfTheMonth.image.localFile.childImageSharp.gatsbyImageData} alt={ambassadorOfTheMonth?.firstName} />}
                    </div>
                    <div className="col">
                        <div className="info">
                            <span className="first-name">
                                {ambassadorOfTheMonth?.firstName}
                            </span>
                            <span className="last-name">
                                {ambassadorOfTheMonth?.lastName}
                            </span>
                            <span className="location">
                                {ambassadorOfTheMonth?.location}
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className="leader-of-the-month">
                <div className="feature-row">
                    <div className="col">
                        <Logo />
                        <h3>
                            State Leader of the month
                        </h3>
                    </div>
                    <div className="col">
                        {stateLeaderOfTheMonth?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={stateLeaderOfTheMonth.image.localFile.childImageSharp.gatsbyImageData} alt={stateLeaderOfTheMonth.firstName} />}
                    </div>
                    <div className="col">
                        <div className="info">
                            <span className="first-name">
                                {stateLeaderOfTheMonth?.firstName}
                            </span>
                            <span className="last-name">
                                {stateLeaderOfTheMonth?.lastName}
                            </span>
                            <span className="location">
                                {stateLeaderOfTheMonth?.location}
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <PageHeader>
                <h2>
                    {signUp?.title}
                </h2>
                {signUp?.content && parse(signUp.content)}
                {signUp?.button && (
                    <Fragment>
                        <Link to={signUp.button.url} className="button black" target={signUp.button.target}>
                            {signUp.button.title}
                        </Link>
                    </Fragment>
                )}
                <div style={{ color: 'rgba(0, 0, 0, 0.54)', margin: '3rem 0 3rem' }}>
                    {signUp?.finePrint && parse(signUp.finePrint)}
                </div>
            </PageHeader>

            <section className="links">
                <div className="container">
                    <div className="row">
                        {buttons && buttons.length && buttons.map(item => (
                            <div className="col" key={item.button.title}>
                                <Link to={item.button.url} className="button blue" target={item.button.target}>
                                    {item.button.title}
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Modal show={isOpen} toggleModal={handleToggleDialog}>
                <iframe src={formUrl} frameBorder="0" width="100%" height="500px"></iframe>
            </Modal>

        </Fragment>
    )
}

export default BecomeAmbassador
