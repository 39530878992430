import React from 'react'
import parse from 'html-react-parser'
import { Link } from "gatsby"

import '../../scss/gray-box.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const GrayBox = ({ data }) => {
    const { column1, column2 } = data
    return (
        <section className="gray-box">
            <div className="container">
                <div className="row">
                    {column1?.title ? (
                        <div className="col">
                            <h3>
                                {column1?.title}
                            </h3>
                            {column1?.content && parse(column1.content)}
                            {column1?.button && column1?.button?.url && column1?.button?.title && !column1?.button?.target && (
                                <Link to={column1.button.url} target={column1.button.target} className="button blue">
                                    {column1.button.title}
                                </Link>
                            )}
                            {column1?.button && column1?.button?.url && column1?.button?.title && column1?.button?.target && (
                                <a href={column1.button.url} target={column1.button.target} className="button blue">
                                    {column1.button.title}
                                </a>
                            )}
                        </div>
                    ) : null}
                    {column2?.title ? (
                        <div className="col">
                            <div className="split">
                                <div className="image">
                                    {column2?.image?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={column2.image.localFile.childImageSharp.gatsbyImageData} alt={column2.image.altText} />}
                                </div>
                                <div className="content">
                                    <h3>
                                        {column2?.title}
                                    </h3>
                                    {column2?.content && parse(column2.content)}
                                    {column2?.button && column2?.button?.url && column2?.button?.title && (
                                        <Link to={column2.button.url} target={column2.button.target} className="button blue">
                                            {column2.button.title}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    )
}

export default GrayBox
