import React, { Fragment } from 'react'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import PageHeader from '../template-parts/page-header'
import GradientHeader from '../template-parts/gradient-header'


import '../../scss/challenges.scss'
import { GatsbyImage } from 'gatsby-plugin-image'


const Challenges = ({ data: { page }, pageContext }) => {

    const query = useStaticQuery(graphql`
        query ChallengesQuery {
            allWpChallengeType {
                challengeType: nodes {
                    slug
                    name
                    description
                    challenges {
                        nodes {
                            title
                            content
                            challengeData: challenges {
                                funds
                                stats
                                date
                            }
                        }
                    }
                    challengeType {
                        logo {
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
            }
            allWpChallenge(limit: 2, sort: {order: DESC, fields: date}) {
                challenges: nodes {
                    title
                    content
                    challenges {
                        stats
                        funds
                        date
                        button {
                            title
                            url
                            target
                        }
                        button2 {
                            title
                            url
                            target
                        }
                        expiresOn
                    }
                    featuredImage {
                        node {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        quality: 100
                                        placeholder: BLURRED
                                        layout: FULL_WIDTH
                                        aspectRatio: 1.25
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const { allWpChallengeType: { challengeType }, allWpChallenge: { challenges }  } = query

    const { pageHeader, currentChallenge, pastChallenges } = page.pageContent.challenges

    const stats = pageContext.impactData

    const date = new Date();
    const currentDate = date.toLocaleDateString();

    const dateExpires = new Date(challenges[0]?.challenges?.expiresOn); // MM/DD/YYYY format
    const dateToday = new Date(currentDate); // MM/DD/YYYY format

    const isExpired = (dateExpires <= dateToday) ? true : false;

    console.log(stats, 'stats')

    return (
        <Fragment>
            <PageHeader>
                <h2>
                    {pageHeader?.title}
                </h2>
                {pageHeader?.content && parse(pageHeader.content)}
            </PageHeader>
            
            <GradientHeader
                title={currentChallenge.title}
                content={currentChallenge.content}
                reverse={true}
            />

            {isExpired && (
                <section className="current-challenge">
                    <div className="container">
                        <div className="row">
                            <div className="content">
                                <h3>
                                    No challenges at the moment
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {!isExpired && challenges.map(challenge => (
                <section className="current-challenge">
                    <div className="container">
                        <div className="row">
                            <div className="image">
                                {challenge?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={challenge.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={challenge.featuredImage.node.altText ? challenge.featuredImage.node.altText : challenge.title} />}
                            </div>
                            <div className="content">
                                <h3>
                                    {challenge.title}
                                </h3>

                                {challenge?.content && parse(challenge.content)}

                                <div className="buttons">
                                    {challenge?.challenges?.button?.url && challenge?.challenges?.button?.title && (
                                        <div className="button-wrap">
                                            <Link to={challenge.challenges?.button.url} target={challenge.challenges?.button.target} className="button black">
                                                {challenge.challenges?.button.title}
                                            </Link>
                                        </div>
                                    )}
                                    {challenge?.challenges?.button2?.url && challenge?.challenges?.button2?.title && (
                                        <div className="button-wrap">
                                            <Link to={challenge.challenges?.button2.url} target={challenge.challenges?.button2.target} className="button black">
                                                {challenge.challenges?.button2.title}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}

            <section className="gray-box">
                <div className="container">
                    <h3>
                        {pastChallenges?.title}
                    </h3>
                    {pastChallenges?.content && parse(pastChallenges.content)}

                    {pastChallenges?.totalFundsRaised && (
                        <Fragment>
                        <p>Total Funds Raised</p>
                        <strong>{pastChallenges.totalFundsRaised}</strong>
                        </Fragment>
                    )}

                    {pastChallenges?.totalParticipants && (
                        <Fragment>
                        <p>Total Participants</p>
                        <strong>{pastChallenges.totalParticipants}</strong>
                        </Fragment>
                    )}

                    {/*{stats?.funds_raised && (
                        <Fragment>
                        <p>Total Funds Raised</p>
                        <strong>${stats.funds_raised.toLocaleString('en-US')}</strong>
                        </Fragment>
                    )}

                    {stats?.all_time_participants && (
                        <Fragment>
                        <p>Total Participants</p>
                        <strong>{stats.all_time_participants.toLocaleString('en-US')}</strong>
                        </Fragment>
                    )}*/}
                </div>
            </section>

            {/*<section className="past-challenges">
                <div className="container">
                    <div className="row">
                        {challengeType?.length && challengeType.map((type, index) => (
                            <div className="col" key={`challenge-type-${index}`}>
                                {type?.challenges?.nodes && type.challenges.nodes.map(challenge => (
                                    <div>
                                        <strong>{challenge?.challengeData?.funds}</strong>
                                        <img src={type?.challengeType?.logo?.localFile?.publicURL} alt={type?.name} />
                                        <p>
                                            {type?.description}
                                        </p>
                                        <ul className="challenge-list">
                                                <li>
                                                    <strong>
                                                        {challenge?.challengeData?.date}
                                                    </strong>
                                                    <span>
                                                        {challenge?.challengeData?.stats}
                                                    </span>
                                                </li>
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </section>*/}

        </Fragment>
    )
}

export default Challenges
