import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const OurPrograms = ({ data }) => {
    return (
        <section className="programs">
            <div className="container">
                <div className="heading">
                    <h2>
                        {data.title}
                    </h2>
                    {data?.content && parse(data.content)}
                </div>
                <div className="row">
                    {data.columns && data.columns.length && data.columns.map((column, index) => (
                        <div className="col" key={`program-${index}`}>
                            <div className="program-card">
                                <div className="image">
                                    {column.button ? (
                                        <Link to={column.button.url} target={column.button.target}>
                                            <GatsbyImage image={column?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={column?.image?.altText}  />
                                        </Link>
                                    ) : (
                                        <GatsbyImage image={column?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={column?.image?.altText}  />
                                    )}
                                </div>
                                <div className="content">
                                    <span className="tag">
                                        {column.title}
                                    </span>
                                    {column.content ? parse(column.content) : ''}
                                    {column.button && (
                                        <Link className="button blue" to={column.button.url} target={column.button.target}>
                                            {column.button.title}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OurPrograms
