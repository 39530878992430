import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import ShopNav from '../template-parts/shop-nav'
import '../../scss/shop.scss'

const CollectionGrid = () => {

    const {
        allShopifyCollection: { collections }
    } = useStaticQuery(graphql`
        query CollectionsQueryNav {
            allShopifyCollection(sort: {order: DESC, fields: updatedAt}) {
                collections: nodes {
                    title
                    handle
                    image {
                        gatsbyImageData(
                            layout: FULL_WIDTH,
                            aspectRatio: 1,
                            placeholder: BLURRED,
                            formats: [AUTO, WEBP]
                        )
                    }
                }
            }
        }
    `)

    return (
        <div className="container">
            <ShopNav />
            {collections && (
                <section className="collections-grid">
                    {collections.map((collection, index) => (
                        <Link to={`/shop/${collection.handle}`} className="collection" key={collection.handle}>
                            <div className="image">                                
                                <GatsbyImage
                                    objectFit="cover"
                                    alt={collection.image.altText}
                                    image={collection.image.gatsbyImageData}
                                />
                            </div>
                            <div className="overlay">
                                <h3>
                                    {collection.title}
                                </h3>
                            </div>
                        </Link>
                    ))}
                </section>
            )}
        </div>
    )
}

const Shop = ({ data : { page }}) => {
    return (
        <main className="shop-main">
            <CollectionGrid />
        </main>
    )
}

export default Shop
