import React from 'react'
import { Link } from 'gatsby'

import '../../scss/crisis-bar.scss'

const ContentBar = ({ color, backgroundColor, children, button, centered = false }) => {
    console.log('but', button)
    return (
        <section className="crisis-bar" style={{ backgroundColor: backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.1)', color: color ? color : '#000000' }}>
            <div className="container">
                <div className={`row ${centered ? 'centered' : ''}`}>
                    <div className="col">
                        {children}
                    </div>
                    {centered === false && (
                        <div className="col">
                            {button && (!button.target || button.target === '') && (
                                <Link to={button.url.replace('https://data.mission22.com', '')} className={`button black ${button.title.toLowerCase().replace(/\s/g, '')}`}>
                                    {button.title}
                                </Link>
                            )}
                            {button && button.target !== '' && (
                                <a href={button.url} target={button.target} className={`button black ${button.title.toLowerCase().replace(/\s/g, '')}`}>
                                    {button.title}
                                </a>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ContentBar
