import React from 'react'
import parse from 'html-react-parser'

import '../../scss/faq.scss'

const FAQ = ({ data: { page } }) => {

    const { pageContent: { faq: { faqs } } } = page

    return (
        <section className="faq-wrapper">
            <div className="container">
                {faqs && faqs.length && faqs.map((faq, i) => (
                    <details className="faq" key={`faq-${i}`} open={i === 0 ? true : false}>
                        <summary>{faq.question}</summary>
                        {parse(faq.answer)}
                    </details>
                ))}
            </div>
        </section>
    )
}

export default FAQ