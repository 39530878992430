import React from 'react'
import parse from 'html-react-parser'
import '../../scss/faq.scss'

const Crypto = ({ data: { page } }) => {
    const { pageContent: { crypto } } = page

    return (
        <section className="crypto-wrapper faq-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="content">
                            <h2>{crypto.cryptoTitle}</h2>
                            {parse(crypto.cryptoContent)}
                        </div>
                    </div>
                    <div className="col">
                        {parse(crypto.cryptoEmbed)}
                    </div>
                </div>
                <div className="faqs">
                    <h2>{crypto.cryptoFaq.cryptoFaqTitle}</h2>
                    {crypto.cryptoFaq.cryptoFaqFaqs && crypto.cryptoFaq.cryptoFaqFaqs.length && crypto.cryptoFaq.cryptoFaqFaqs.map((faq, i) => (
                        <details className="faq" key={`faq-${i}`} open={i === 0 ? true : false}>
                            <summary>{faq.question}</summary>
                            {parse(faq.answer)}
                        </details>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Crypto
