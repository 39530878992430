import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import SwiperCore, { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/scrollbar'

SwiperCore.use([Scrollbar])

const Timeline = ({ timelineItems }) => {
    return (
        <section className="timeline">
            <div className="wrapper">
                <Swiper spaceBetween={0} slidesPerView={'auto'} modules={[Scrollbar]} scrollbar={{ draggable: true }}>
                    {timelineItems?.length && timelineItems.map(item => (
                        <SwiperSlide key={item}>
                            <div className="item">
                                <div className="date">
                                    {item.date}
                                </div>
                                <div className="event">
                                    {item?.link && (
                                        <Fragment>
                                            {item?.link?.target && (
                                                <a href={item?.link?.url} target={item?.link?.target}>{item.event}</a>
                                            )}
                                            {!item?.link?.target && (
                                                <Link to={item?.link?.url}>{item.event}</Link>
                                            )}
                                        </Fragment>
                                    )}
                                    {!item?.link && (
                                        <Fragment>
                                            {item.event}
                                        </Fragment>
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}

export default Timeline
