import React from 'react'
import parse from 'html-react-parser'
import { Link } from "gatsby"

import '../../scss/donate-box.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Helmet } from "react-helmet"

const GrayBox = ({ data }) => {
    console.log(data?.bacckground, 'bg')
    return (
        <section className="donate-box">
            <div className="background">
                {data?.bacckground?.localFile?.childImageSharp?.gatsbyImageData && <GatsbyImage image={data.bacckground.localFile.childImageSharp.gatsbyImageData} alt={data?.bacckground?.altText} />}
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h3>
                            {data?.title}
                        </h3>
                        {data?.content && parse(data.content)}
                    </div>
                    <div className="col">
                        <div className="donate-embed"></div>
                    </div>
                </div>
            </div>
            <Helmet>
                <script>
                    {`isBrowser = typeof window !== "undefined"

                    if (isBrowser) {
                        window.addEventListener('load', function () {
                            setTimeout(initClassy, 1000);
                            function initClassy() {
                                ;(function (win) {
                                    win.egProps = {
                                        campaigns: [
                                            {
                                                campaignId: '473246',
                                                customDomain: 'give.mission22.com',
                                                donation: {
                                                    inline: {
                                                        urlParams: { },
                                                        elementSelector: '.donate-embed'
                                                    },
                                                    // modal: {
                                                    //     urlParams: { },
                                                    //     elementSelector: '.main-header .btn a, .footer-content .button, .crisis-bar .donate',
                                                    // },
                                                    // // ADD ABANDON CART NUDGE CODE HERE TO ENABLE
                                                    // nudgeTrays: [
                                                    //     // CUSTOMIZE YOUR MESSAGE
                                                    //     {
                                                    //         title: 'Make a difference 💜',
                                                    //         content: 'Complete your gift to help make our mission possible.',
                                                    //         ctaLabel: 'Give Now',
                                                    //         ctaColor: '#1a73e8',
                                                    //         triggerEvent: 'eg:donation:incomplete',
                                                    //     },
                                                    // ],
                                                },
                                            }
                                        ],
                                    }

                                    win.document.body.appendChild(makeEGScript());

                                    /**
                                    * Create the embed script
                                    */
                                    function makeEGScript() {
                                        var egScript = win.document.createElement('script');
                                        egScript.setAttribute('type', 'text/javascript');
                                        egScript.setAttribute('async', 'true');
                                        egScript.setAttribute('src', 'https://sdk.classy.org/embedded-giving.js');

                                        return egScript;
                                    }

                                    /**
                                    * Read URL Params from your website. This could potentially
                                    * be included in the embed snippet
                                    */
                                    function readURLParams() {
                                        const searchParams = new URLSearchParams(window.location.search)

                                        const validUrlParams = ['c_src', 'c_src2'];

                                        return validUrlParams.reduce(function toURLParamsMap(urlParamsSoFar, validKey) {
                                            const value = searchParams.get(validKey);
                                            return value === null ? urlParamsSoFar : { ...urlParamsSoFar, [validKey]: value }
                                        }, {});
                                    }
                                })(window)
                            }
                        }, false);
                    }`}
                </script>
            </Helmet>
        </section>
    )
}

export default GrayBox
