import React, { Fragment } from "react"
import parse from 'html-react-parser'

import ContentBar from "../template-parts/content-bar"
import PartnerItem from "../template-parts/partner-item";

import '../../scss/give.scss'

const Give = ({ data: { page } }) => {

    const { 
        donate,
        crypto,
        partner,
        shop,
        extraBox,
        partnerStores
     } = page.pageContent.give

    return (
        <Fragment>
            <ContentBar color={'#ffffff'} backgroundColor={'#003366'} button={donate?.button}>
                <h3>
                    {donate?.title}
                </h3>
                {donate?.content && parse(donate.content)}
            </ContentBar>
            <ContentBar color={'#ffffff'} backgroundColor={'rgba(0, 51, 102, 0.85)'} button={crypto?.button}>
                <h3>
                    {crypto?.title}
                </h3>
                {crypto?.content && parse(crypto.content)}
            </ContentBar>
            <ContentBar backgroundColor={'rgba(0, 0, 0, 0.3)'} button={partner?.button}>
                <h3>
                    {partner?.title}
                </h3>
                {partner?.content && parse(partner.content)}
            </ContentBar>
            <ContentBar backgroundColor={'rgba(0, 0, 0, 0.1)'} button={shop?.button} centered={false}>
                <h3>
                    {shop?.title}
                </h3>
                {shop?.content && parse(shop.content)}
            </ContentBar>
            <ContentBar backgroundColor={'rgba(0, 0, 0, 0.05)'} button={extraBox?.button} centered={false}>
                <h3>
                    {extraBox?.title}
                </h3>
                {extraBox?.content && parse(extraBox.content)}
            </ContentBar>
            <section className="partner-shops">
                <div className="container">
                    <div className="partner-wrapper">
                        {partnerStores && partnerStores.length && partnerStores.map((store, index) => (
                            <PartnerItem key={`partner-store-${index}`} store={store} />
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Give
