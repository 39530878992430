import React from 'react'
import { formatPrice } from "../../utils/format-price"

import Star from '../../svg/large-star.svg'

const OurImpact = ({ data, stats }) => {
    console.log('stats', stats)
    return (
        <section className="our-impact">
            <div className="container">
                <div className="heading">
                    <h2>
                        {data.title}
                    </h2>
                </div>
                <div className="row">
                    
                    <div className="col">
                        <div className="star-card">
                            <div className="star">
                                <Star />
                            </div>
                            <div className="content">
                                <p>
                                    VETERANS AND FAMILIES SERVED
                                </p>
                                <strong>
                                    {stats.all_time_participants.toLocaleString('en-US')}
                                </strong>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="star-card">
                            <div className="star">
                                <Star />
                            </div>
                            <div className="content">
                                <p>
                                    FUNDING INTO PROGRAMS
                                </p>
                                <strong>
                                    {formatPrice('USD', Math.round(stats.total_funds_spent), false)}
                                </strong>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="star-card">
                            <div className="star">
                                <Star />
                            </div>
                            <div className="content">
                                <p>
                                    VOLUNTEER AMBASSADORS
                                </p>
                                <strong>
                                    {stats.total_ambassadors.toLocaleString('en-US')}
                                </strong>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default OurImpact
