import React, { Fragment } from "react"

import '../../scss/home.scss'

import BlackBox from "../template-parts/black-box";
import OurImpact from "../template-parts/our-impact";
import OurPrograms from "../template-parts/our-programs";
import ImpactStories from "../template-parts/impact-stories";
import EventsSnapshot from "../template-parts/events-snapshot";
import DonateBox from "../template-parts/donate-box";
import GrayBox from "../template-parts/gray-box";

const Home = ({ data : { page, collections }, pageContext }) => {
    const {
        pageContent: {
            home: {
                blackBox,
                events,
                impact,
                ourImpact,
                programs,
                donateBox,
                greyBox
            }
        }
    } = page
    return (
        <Fragment>
            <BlackBox data={blackBox} />
            <OurImpact data={ourImpact} stats={pageContext.impactData} />
            <OurPrograms data={programs} />
            <ImpactStories data={impact} />
            <EventsSnapshot data={events} />
            <DonateBox data={donateBox} />
            <GrayBox data={greyBox} />
        </Fragment>
    )
}

export default Home
