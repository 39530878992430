import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'
import { GatsbyImage } from 'gatsby-plugin-image';

import '../../scss/financials.scss'

const Financials = ({ data: { page } }) => {
    const { financialsBox1, financialsBox2, imageEmbed, financialsButtons, fileLists } = page.pageContent.financials;

    return (
        <Fragment>
            <div className="container">
                {(financialsBox1?.financialsBox1Title || financialsBox2?.financialsBox2Title) ? (
                    <div className="blue-boxes">
                        {(financialsBox1 && financialsBox1.financialsBox1Title) ? (
                            <div className="blue-box">
                                <h2>{financialsBox1.financialsBox1Title}</h2>
                                {(financialsBox1.financialsBox1Price) ? (
                                    <strong className="price price-large">{financialsBox1.financialsBox1Price}</strong>
                                ) : null}
                                {(financialsBox1.financialsBox1Title2) ? (
                                    <h3>{financialsBox1.financialsBox1Title2}</h3>
                                ) : null}
                                {(financialsBox1.financialsBox1Description) ? (
                                    <p>{financialsBox1.financialsBox1Description}</p>
                                ) : null}
                            </div>
                        ) : null}

                        {(financialsBox2 && financialsBox2.financialsBox2Title) ? (
                            <div className="blue-box">
                                <h2>{financialsBox2.financialsBox2Title}</h2>
                                {(financialsBox2.financialsBox2Subtitle) ? (
                                    <div className="sub-title">{financialsBox2.financialsBox2Subtitle}</div>
                                ) : null}
                                {(financialsBox2.financialsBox2Price) ? (
                                    <strong className="price">{financialsBox2.financialsBox2Price}</strong>
                                ) : null}
                                {(financialsBox2.financialsBox2Title2) ? (
                                    <h3>{financialsBox2.financialsBox2Title2}</h3>
                                ) : null}
                                {(financialsBox2.financialsBox2Description) ? (
                                    <p>{financialsBox2.financialsBox2Description}</p>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {(imageEmbed) ? (
                    <div className="image-embed">
                        {parse(imageEmbed)}
                    </div>
                ) : null}

                {(financialsButtons && financialsButtons.length) ? (
                    <div className="buttons">
                        {(financialsButtons.map(button => {
                            const but = button.button

                            return (
                                <Link to={but.url} className="button">{but.title}</Link>
                            )
                        }))}
                    </div>
                ) : null}

                <div className="file-lists">
                    {(fileLists && fileLists.map(section => {
                        return (
                            <div className="file-list">
                                {(section.sectionTitle) ? (
                                    <h3>{section.sectionTitle}</h3>
                                ) : null}
                                {(section.fileList && section.fileList.map(file => {
                                    const archive = file.archiveFile

                                    return (
                                        <a href={archive.mediaItemUrl} className="file" title={archive.title} target="_blank">{archive.title}</a>
                                    )
                                }))}
                            </div>
                        )
                    }))}
                </div>
            </div>
        </Fragment>
    )
}

export default Financials
