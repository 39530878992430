import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import parse from 'html-react-parser'

import Layout from "../components/layout"
import Hero from "../components/template-parts/hero"

import Home from "../components/template/home"
import Shop from "../components/template/shop"
import Memorials from "../components/template/memorials"
import Challenges from "../components/template/challenges"
import Team from "../components/template/team"
import FAQ from "../components/template/faq"
import About from "../components/template/about"
import BecomeAmbassador from "../components/template/become-ambassador"
import Give from "../components/template/give"
import Crisis from "../components/template/crisis"
import WeRemember from "../components/template/we-remember"
import Network from "../components/template/network"
import Financials from "../components/template/financials"
import Crypto from "../components/template/crypto"

const PageTemplate = ({ data: { page, collections }, pageContext }) => {

  const { pageContent } = page

  const pages = {
		home: Home,
		shop: Shop,
		memorials: Memorials,
		challenges: Challenges,
		team: Team,
		faq: FAQ,
		about: About,
		becomeAmbassador: BecomeAmbassador,
		give: Give,
		crisis: Crisis,
		weRemember: WeRemember,
		network: Network,
		financials: Financials,
		crypto: Crypto,
  }

  return (
	<Layout isProductPage={false}>
	  <Seo post={page} />
	  <Hero page={page} />
	  {pageContent.pageTemplate && pageContent.pageTemplate in pages ? (
		React.createElement(pages[pageContent.pageTemplate], { data: { page, collections }, pageContext }, null)
	  ) : (
		<div className="container">
		  {page.content && parse(page.content)}
		</div>
	  )}
	</Layout>
  )
}

export default PageTemplate


export const pageQuery = graphql`
  query PageById(
	# these variables are passed in via createPage.pageContext in gatsby-node.js
	$id: String!
  ) {
	# selecting the current page by id
	page: wpPage(id: { eq: $id }) {
		id
		slug
		title
		content
		seo {
			title
			metaDesc
			focuskw
			metaKeywords
			opengraphTitle
			opengraphDescription
			opengraphImage {
				altText
				sourceUrl
				srcSet
			}
			twitterTitle
			twitterDescription
			twitterImage {
				altText
				sourceUrl
				srcSet
			}
			canonical
			cornerstone
			schema {
				articleType
				pageType
				raw
			}
		}
		hero {
			backgroundImage {
				localFile {
					childImageSharp {
						gatsbyImageData(
							width: 2000
							placeholder: BLURRED
							formats: [AUTO, WEBP, JPG]
						)
					}
				}
	  	}
	  	title
	  	content
		 	largeHero
		}
		pageContent {
			pageTemplate
			# Home Page
			home {
				blackBox {
					content
					button {
						url
						title
						target
					}
				}
				events {
					title
					content
				}
				impact {
					content
					title
					stories {
						title
						story
						date(formatString: "MMMM DD, YYYY")
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: CONSTRAINED
										aspectRatio: 1.7777
									)
								}
							}
							altText
						}
					}
				}
				ourImpact {
					title
					columns {
						title
						number
						image {
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: FULL_WIDTH
									)
								}
							}
							altText
						}
					}
				}
				programs {
					title
					content
					columns {
						title
						content
						button {
							target
							title
							url
						}
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: CONSTRAINED
										aspectRatio: 1.7777
									)
								}
							}
						}
					}
				}
				donateBox {
					title
					content
					bacckground {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
								)
							}
						}
					}
				}
				greyBox {
					column1 {
						title
						content
						button {
							target
							title
							url
						}
					}
					column2 {
						title
						content
						button {
							target
							title
							url
						}
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: CONSTRAINED
										aspectRatio: 1
									)
								}
							}
						}
					}
				}
			}
			# Program Category Page
			programCategory {
				pageHeader {
					content
					title
				}
			}
			# FAQ Page
			faq {
				faqs {
					answer
					question
				}
			}
			# Memorials Page
			memorials {
				pageHeader {
					title
					content
					button {
						target
						title
						url
					}
				}
				warAtHome {
					video
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: CONSTRAINED
									aspectRatio: 1.7777
								)
							}
						}
					}
					title
					gallery {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: CONSTRAINED
									aspectRatio: 1.7777
								)
							}
						}
					}
					content
					button {
						target
						title
						url
					}
					button2 {
						target
						title
						url
					}
				}
				memorials {
					title
					content
					buttons {
						button {
							target
							title
							url
						}
					}
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: CONSTRAINED
									aspectRatio: 1.7777
								)
							}
						}
					}
				}
			}
			# We Remember Page
			weRemember {
				pageHeader {
					content
				}
				veterans {
					person {
						name
						branch
						rank
						birthDate
						deathDate
						image {
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: FULL_WIDTH
										aspectRatio: 1
									)
								}
							}
						}
					}
				}
			}
			# Become an Ambassador
			becomeAnAmbassador {
				pageHeader {
					title
					content
					button {
						target
						title
						url
					}
				}
				aboutAmbassadors {
					content
					imageTop {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
									aspectRatio: 5
								)
							}
						}
					}
					imageRight {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
									aspectRatio: 0.75
								)
							}
						}
					}
				}
				ambassadorOfTheMonth {
					firstName
					lastName
					location
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
									aspectRatio: 1
								)
							}
						}
					}
				}
				stateLeaderOfTheMonth {
					firstName
					lastName
					location
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
									aspectRatio: 1
								)
							}
						}
					}
				}
				signUp {
					title
					content
					button {
						target
						title
						url
					}
					finePrint
				}
				buttons {
					button {
						target
						title
						url
					}
				}				
			}
			# Challenges
			challenges {
				pageHeader {
					title
					content
				}
				currentChallenge {
					title
					content
				}
				pastChallenges {
					title
					content
					totalFundsRaised
					totalParticipants
				}
			}
			# Give
			give {
				donate {
					title
					content
					button {
						target
						title
						url
					}
				}
				crypto {
					title
					content
					button {
						target
						title
						url
					}
				}
				partner {
					title
					content
					button {
						target
						title
						url
					}
				}
				shop {
					title
					content
					button {
						target
						title
						url
					}
				}
				extraBox {
					title
					content
					button {
						target
						title
						url
					}
				}
				partnerStores {
					name
					description
					button {
						url
						title
						target
					}
					logo {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: CONSTRAINED
									width: 200
									aspectRatio: 1
								)
							}
						}
					}
				}
			}
			# About the mission
			aboutTheMission {
				story {
					title
					content
					video
				}
				ourMission {
					title
					content
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
									aspectRatio: 1
								)
							}
						}
					}
				}
				timeline {
					date
					event
					link {
						target
            title
            url
					}
				}
				finePrint {
					content
					badges {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
			# Team
			team {
				pageHeader {
					title
					content
				}
			}
			# Crisis
			crisis {
				content
				boxes {
          buttons {
            link {
              target
              title
              url
            }
            title
          }
          phone
          title
        }
        title
				blueBox
			}
			#Network
			network {
				featuredItem {
					video
					link {
						url
					}
					image {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
				items {
					video
					link {
						url
					}
					image {
						localFile {
							childImageSharp {
								gatsbyImageData(
									quality: 100
									placeholder: BLURRED
									layout: FULL_WIDTH
								)
							}
						}
					}
				}
			}
			# Financials
			financials {
        fileLists {
          sectionTitle
          fileList {
            archiveFile {
              title
              mediaItemUrl
            }
          }
        }
        financialsButtons {
          button {
            target
            title
            url
          }
        }
        financialsBox1 {
          financialsBox1Description
          financialsBox1Price
          financialsBox1Title
          financialsBox1Title2
        }
        financialsBox2 {
          financialsBox2Description
          financialsBox2Price
          financialsBox2Subtitle
          financialsBox2Title
          financialsBox2Title2
        }
        imageEmbed
      }
      # Crypto
      crypto {
        cryptoContent
        cryptoEmbed
        cryptoFaq {
          cryptoFaqFaqs {
            answer
            question
          }
          cryptoFaqTitle
        }
        cryptoTitle
      }
		}
	}
}
`
