import React, { Fragment } from 'react'
import parse from 'html-react-parser'
import ReactPlayer from 'react-player'
import { GatsbyImage } from 'gatsby-plugin-image';

import Timeline from '../template-parts/timeline';

import '../../scss/about.scss'

const About = ({ data: { page } }) => {

    const { story, ourMission, timeline, finePrint } = page.pageContent.aboutTheMission

    return (
        <Fragment>
            <section className="about-intro">
                <div className="container">
                    <div className="shield-container">
                        <div className="content">
                            <h2>
                                {story?.title}
                            </h2>
                            {story?.content && parse(story.content)}
                        </div>
                    </div>
                </div>
            </section>
            <section className="blue-box">
                <div className="box-wrapper">
                    <div className="image">
                        <div className="video-wrapper">
                            {story?.video && ReactPlayer.canPlay(story?.video) && <ReactPlayer className="video" url={story.video} controls />}
                        </div>
                    </div>
                    <div className="content">
                        <h3>
                            {ourMission?.title}
                        </h3>
                        {ourMission?.content && parse(ourMission.content)}
                    </div>
                </div>
            </section>

            <Timeline timelineItems={timeline} />

            <section className="fine-print">
                <div className="container">
                    <div className="badges">
                        {finePrint?.badges && finePrint?.badges.map(badge => {
                            return (
                                <GatsbyImage image={badge.localFile.childImageSharp.gatsbyImageData} alt={badge.altText} />
                            )
                        })}
                    </div>

                    {finePrint?.content && parse(finePrint.content)}
                </div>
            </section>
        </Fragment>
    )
}

export default About
