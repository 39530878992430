import React, { Fragment } from 'react'
import parse from 'html-react-parser'
import { useStaticQuery, graphql } from 'gatsby'

import Department from '../template-parts/department'
import PageHeader from '../template-parts/page-header'

import '../../scss/team.scss'

const Team = ({ data: { page } }) => {

    const queryData = useStaticQuery(graphql`
        query TeamQuery {
            allWpTeamMember( sort: { order: DESC, fields: menuOrder } ) {
                teamMembers: nodes {
                    id
                    title
                    slug
                    content
                    departments {
                        nodes {
                            slug
                            id
                        }
                    }
                    featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        quality: 100
										placeholder: BLURRED
										layout: FULL_WIDTH
										aspectRatio: 1
                                    )
                                }
                            }
                        }
                    }
                }
            }
            allWpDepartment {
                departments: nodes {
                    slug
                    name
                    description
                }
            }
        }
    `)

    const { pageHeader } = page.pageContent.team

    const move = (arr, from, to) => {
        const newArr = arr.splice(to, 0, arr.splice(from, 1)[0]);
        console.log(newArr)
        return newArr
    };

    let { allWpTeamMember: { teamMembers }, allWpDepartment: { departments }  } = queryData
    
    departments.map(department => {
        department.members = teamMembers.filter(member => member.departments.nodes.some(item => item.slug === department.slug))
        return department
    })
    
    return (
        <Fragment>
            <PageHeader>
                <h2>
                    {pageHeader?.title}
                </h2>
                {pageHeader?.content && parse(pageHeader.content)}
            </PageHeader>
            {departments && departments.length && departments.map((department, i) => (
                <Department department={department} index={i} key={department.slug}  />
            ))}
        </Fragment>
    )
}

export default Team
